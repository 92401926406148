import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MyMap } from "../_components/map";
import { userService } from "../_services/user.service";
import logo from "./logo.svg";
import { MyVehicles } from "../_components/myVehicles";
import AddVehicle from "../_components/addVehicle.jsx";
import SelectRouteForm from "../_components/selectRouteForm.jsx";
import polyUtil from "polyline-encoded";
import { authHeader } from "../_helpers";
import FadeLoader from "react-spinners/FadeLoader";
import { prop, contains } from "ramda";
import { Table, Label, ListGroup, ListGroupItem } from "reactstrap";
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getRoutesRequest: null,
      selectedVehicle: "None",
      selectedVehicleId: null,
      getVehiclesRequest: null,
      routeTime: null,
      routePolyline: null,
      decodedPolyline: null,
      suitableBridges: null,
      notSuitableBridges: null,
      path_bridges_compared_from_db: null,
      time: null,
      distance: null,
      bridges: null,
      getWayFrom: null,
      getWayTo: null,
      pathBridges: null,
      loading: false,
      loadData: "None",
      bridgeTypeToShow: [1],
      selectedBridgeId: null,
      bridges_impassable_by_rounds: null,
      totalMass: "None",
    };
    this.mapRef = React.createRef();
    this.fetchRoutesData = this.fetchRoutesData.bind(this);
  }

  componentDidMount() {
    userService.getRoutes().then((data) => {
      this.setState({ getRoutesRequest: data });
    });
    userService.getVehicles().then((data) => {
      this.setState({ getVehiclesRequest: data });
    });
    userService.getBridges().then((data) => this.setState({ bridges: data }));
  }
  updateVehicles() {
    userService.getVehicles().then((data) => {
      this.setState({ getVehiclesRequest: data });
    });
  }
  updateGetWayInputs(getWayFrom, getWayTo) {
    this.setState({ getWayFrom, getWayTo });
  }
  updateBridgeTypeToShow(bridgeType) {
    if (contains(bridgeType, this.state.bridgeTypeToShow)) {
      this.setState({
        bridgeTypeToShow: this.state.bridgeTypeToShow.filter(
          (bridgeToShow) => bridgeToShow !== bridgeType
        ),
      });
    } else {
      this.setState({
        bridgeTypeToShow: [...this.state.bridgeTypeToShow, bridgeType],
      });
    }
  }
  updateLoading(state) {
    this.setState({ loading: state });
  }
  myRoutesDelete(id) {
    fetch(`https://trucktest.stricto.ee/api/routes/${id}`, {
      method: "DELETE",
      headers: Object.assign({}, authHeader(), {
        "Content-Type": "application/json",
      }),
      body: id,
    }).then((response) => {
      userService.getRoutes().then((data) => {
        this.setState({ getRoutesRequest: data });
      });
    });
  }
  mapSetRef(ref) {
    this.setState({ mapRef: ref });
  }
  myVehicleDelete(id) {
    fetch(`https://trucktest.stricto.ee/api/vehicles/${id}`, {
      method: "DELETE",
      headers: Object.assign({}, authHeader(), {
        "Content-Type": "application/json",
      }),
      body: id,
    }).then((response) => {
      userService.getVehicles().then((data) => {
        this.setState({ getVehiclesRequest: data });
      });
    });
  }
  decodePolyline(polyline) {
    const latlngs = polyUtil.decode(polyline);
    this.setState({ decodedPolyline: latlngs });
    console.log(latlngs);
    const map = this.mapRef.current.leafletElement;
    if (latlngs && !this.state.loading) {
      map.fitBounds([
        { lat: latlngs[0][0], lng: latlngs[0][1] },
        {
          lat: latlngs[latlngs.length - 1][0],
          lng: latlngs[latlngs.length - 1][1],
        },
      ]);
    }
  }

  getRouteData(
    routePolyline,
    routeTime,
    suitableBridges,
    notSuitableBridges,
    time,
    distance,
    pathBridges,
    path_bridges_compared_from_db,
    bridges_impassable_by_rounds
  ) {
    this.setState({
      routePolyline,
      routeTime,
      suitableBridges,
      notSuitableBridges,
      time,
      distance,
      pathBridges,
      path_bridges_compared_from_db,
      bridges_impassable_by_rounds,
    });

    this.decodePolyline(routePolyline);
  }
  fetchRoutesData() {
    userService.getRoutes().then((data) => {
      this.setState({ getRoutesRequest: data });
    });
  }

  vehicleSelect(name, id, loadData, totalMass) {
    this.setState({
      selectedVehicleId: id,
      selectedVehicle: name,
      loadData: loadData,
      totalMass: totalMass,
    });
  }
  onSelectBridge(bridgeId) {
    this.setState({
      selectedBridgeId: bridgeId,
    });
  }
  openBridgePopup(bridge) {
    const currentLeafletElement = this.mapRef.current.leafletElement;

    currentLeafletElement.openPopup(
      `${bridge.BridgeNumber} <br /> ${bridge.BridgeName}
      <br />
      MLC Rating: ${bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
      Date of rating: ${bridge.DateAllowedTotalMass}<br />Bridge BMS: ${bridge.BridgeBMS}
      <br />
      Owner: ${bridge.Owner}
      <br />
      Type of structure: ${bridge.TypeOfStructure}
      <br />
      Year built: ${bridge.YearBuilt}
      <br />
      `,
      [bridge.Ycoord, bridge.Xcoord]
    );
    currentLeafletElement.setView([bridge.Ycoord, bridge.Xcoord], 13);
  }
  render() {
    return (
      <div className="container-fluid my-3">
        <div className="row mb-3">
          <div className="col-3 d-flex justify-content-between">
            <img src={logo} alt="Stricto Project" className="logo" />
            <div className="text-uppercase font-weight-bold pl-3 align-self-center text-small">
              Raskeveoste teekonnaplaneerimise prototüüp
            </div>
          </div>
          <div className="col d-flex align-items-center">
            <Label className="mr-4 mb-0">
              State MLC bridges
              <input
                type="checkbox"
                defaultChecked={true}
                onChange={() => this.updateBridgeTypeToShow(1)}
                className={"mx-2"}
                name="vehicle1"
                value="Bike"
              />
            </Label>
            <Label className="mr-4 mb-0">
              State bridges without MLC
              <input
                type="checkbox"
                onChange={() => this.updateBridgeTypeToShow(2)}
                className={"mx-2"}
                name="vehicle1"
                value="Bike"
              />
            </Label>
            <Label className="mr-4 mb-0">
              Local bridges without MLC
              <input
                type="checkbox"
                onChange={() => this.updateBridgeTypeToShow(3)}
                className={"mx-2"}
                name="vehicle1"
                value="Bike"
              />
            </Label>
          </div>
          <div className="col-1 text-right">
            User: {this.props.user.user.username}
            <br />
            <Link to="/login">Logout</Link>
          </div>
        </div>
        <div className="row ">
          {this.state.loading && (
            <div class="loading">
              <FadeLoader></FadeLoader>
            </div>
          )}
          <div className="col-4 col-lg-3">
            <MyVehicles
              className="modal-xl "
              vehicleSelect={this.vehicleSelect.bind(this)}
              vehicleDelete={this.myVehicleDelete.bind(this)}
              updateVehicles={this.updateVehicles.bind(this)}
              vehicles={this.state.getVehiclesRequest}
              buttonLabel="My Vehicles"
            />
            <div className="float-right">
              <AddVehicle
                vehicleSelect={this.vehicleSelect.bind(this)}
                className="modal-xl"
                buttonLabel="Add Vehicle"
                updateVehicles={this.updateVehicles.bind(this)}
              />
            </div>
            <div className="my-3">
              Selected vehicle: {this.state.selectedVehicle}
            </div>
            <div className="my-3">MLC class: {this.state.loadData}</div>
            <div className="my-3">
              Total mass:{" "}
              {this.state.totalMass === "None"
                ? "None"
                : this.state.totalMass + " t"}{" "}
            </div>
            <SelectRouteForm
              getRouteData={this.getRouteData.bind(this)}
              selectedVehicle={this.state.selectedVehicleId}
              time={this.state.time}
              distance={this.state.distance}
              tableData={this.state.getRoutesRequest}
              myRoutesDelete={this.myRoutesDelete.bind(this)}
              saveRouteText={this.state.saveRouteText}
              updateGetWayInputs={this.updateGetWayInputs.bind(this)}
              fetchRoutesData={this.fetchRoutesData}
              updateLoading={this.updateLoading.bind(this)}
            />
            {this.state.path_bridges_compared_from_db &&
              this.state.path_bridges_compared_from_db.length > 0 && (
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th>Bridges without MLC</th>
                    </tr>
                  </thead>
                  <tbody className="text-uppercase small bg-white">
                    {this.state.path_bridges_compared_from_db &&
                      this.state.path_bridges_compared_from_db
                        .filter((bridge) => prop("DataType", bridge) !== 1)
                        .map((bridge) => (
                          <tr
                            onClick={() => {
                              this.onSelectBridge(bridge.BridgeNumber);
                              this.openBridgePopup(bridge);
                            }}
                            action
                            className={
                              bridge.BridgeNumber ===
                              this.state.selectedBridgeId
                                ? "selected"
                                : ""
                            }
                          >
                            <td className="text-right bridge-num-col">
                              {bridge.BridgeNumber}
                            </td>
                            <td>{bridge.BridgeName}</td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
          </div>
          <div className="col-8 col-lg-9">
            {this.state.decodedPolyline !== null ? (
              <MyMap
                bridgeTypeToShow={this.state.bridgeTypeToShow}
                position={[58.800552, 24.857666]}
                polyline={this.state.decodedPolyline}
                suitableBridges={this.state.suitableBridges}
                notSuitableBridges={this.state.notSuitableBridges}
                from={this.state.getWayFrom}
                to={this.state.getWayTo}
                pathBridges={this.state.pathBridges}
                loading={this.state.loading}
                path_bridges_compared_from_db={
                  this.state.path_bridges_compared_from_db
                }
                bridges_impassable_by_rounds={
                  this.state.bridges_impassable_by_rounds
                }
                mapSetRef={this.mapRef}
              />
            ) : (
              <MyMap
                position={[58.800552, 24.857666]}
                bridgeTypeToShow={this.state.bridgeTypeToShow}
                allBridges={this.state.bridges}
                loading={this.state.loading}
                bridges_impassable_by_rounds={
                  this.state.bridges_impassable_by_rounds
                }
                mapSetRef={this.mapRef}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6 pt-3 attribution">
            developed by{" "}
            <a
              href="https://amblik.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              amblik.eu
            </a>
          </div>
          <div className="col-6 text-right pt-3 attribution">
            Powered by{" "}
            <a
              href="https://www.graphhopper.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GraphHopper API
            </a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
