import React from "react";
import { FormGroup, Form, Button, Input } from "reactstrap";
import { authHeader } from "../_helpers";
import { userService } from "../_services/user.service";
import AlgoliaPlaces from "algolia-places-react";
import MyRoutes from "./myRoutes";

class SelectRouteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfWayPoints: 0,
      from: null,
      to: null,
      fromAutocompleteObject: null,
      toAutocompleteObject: null,
      saveRouteText: "SAVE ROUTE",
      doesSuggestionWasPickedForInputTo: false,
      doesSuggestionWasPickedForInputFrom: false,
      fromLatLng: null,
      toLatLng: null,
      selectedFromTable: null,
      doesInputSelectedFromStringTo: false,
      doesInputSelectedFromStringFrom: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.routeSelect = this.routeSelect.bind(this);
  }
  routeSelect(from, to, fromLatLng = null, toLatLng =null, selectedFromTable = true, 
    doesInputSelectedFromStringTo= false, doesInputSelectedFromStringFrom=false) {
    this.setState({
      from,
      to,
      doesSuggestionWasPickedForInputTo: false,
      doesSuggestionWasPickedForInputFrom: false,
      fromLatLng,
      toLatLng,
      selectedFromTable
    });
  }

  routeSave(from, to) {
    let optional = {};
    if (this.state.doesSuggestionWasPickedForInputTo) {
      optional["toLatLng"] = this.state.toLatLng;
    }
    if (this.state.doesSuggestionWasPickedForInputFrom) {
      optional["fromLatLng"] = this.state.fromLatLng;
    }
    if (from !== null && to !== null) {
      if (
        Object.keys(optional).length === 0 
      ) {
       
        userService.addRoutes(from, to).then(response => {
          this.setState({ saveRouteText: "SAVED" });
          setTimeout(() => {
            this.setState({ saveRouteText: "SAVE ROUTE" });
          }, 3000);
          this.props.fetchRoutesData();
        });
      } else {
    
        userService.addRoutes(from, to, optional).then(response => {
          this.setState({ saveRouteText: "SAVED" });
          setTimeout(() => {
            this.setState({ saveRouteText: "SAVE ROUTE" });
          }, 3000);
          this.props.fetchRoutesData();
        });
      }
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    
    if (this.state.from !== null && this.state.to !== null) {
      const data = new FormData(event.target);
      const object = {};
      this.props.updateLoading(true)
      data.forEach(function(value, key) {
        object[key] = value;
      });
      object["id"] = this.props.selectedVehicle;
      if (this.state.doesSuggestionWasPickedForInputTo
         && this.state.doesSuggestionWasPickedForInputFrom || (
         this.state.doesInputSelectedFromStringTo ||
         this.state.doesInputSelectedFromStringFrom ) ) {
        this.setState({ selectedFromTable: null})
        
      }
      else{
        object["toLatLng"] = this.state.toLatLng;
        object["fromLatLng"] = this.state.fromLatLng;
      }
      if(this.state.selectedFromTable !== null)
      {
        console.log('testest2')
       
      }
      if (this.state.doesSuggestionWasPickedForInputTo) {
        object["toLatLng"] = this.state.toLatLng;
      }
      if (this.state.doesSuggestionWasPickedForInputFrom) {
        object["fromLatLng"] = this.state.fromLatLng;
      }
      console.log(object);
      const json = JSON.stringify(object);
      this.props.updateGetWayInputs(object.from, object.to);
      fetch("https://trucktest.stricto.ee/api/destination/getWay", {
        method: "POST",
        headers: Object.assign({}, authHeader(), {
          "Content-Type": "application/json"
        }),
        body: json
      })
        .then(userService.handleResponse)
        .then(response => {
          this.props.updateLoading(false)
          console.log(response);
          this.props.getRouteData(
            response.paths[0].points,
            response.paths[0].time,
            response.bridges.suitable_bridges,
            response.bridges.not_suitable_bridges,
            response.paths[0].time,
            response.paths[0].distance,
            response.path_bridges,
            response.path_bridges_compared_from_db,
            response.bridges_impassable_by_rounds
          );
        });
    }
  }
  handleInputChange(name, query) {
    const value = query;
    console.log("input change");

    if (name == "from") {
      this.setState({
        from: value
      });
      this.state.fromAutocompleteObject.setVal(query);
    } else {
      this.setState({
        to: value
      });
      this.state.toAutocompleteObject.setVal(query);
    }
  }
  msToTime(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes;
  }

  handleSaveClick() {
    console.log(this.state.fromAutocompleteObject.getVal());
    //this.props.routeSave(this.state.fromAutocompleteObject.getVal(),   this.state.toAutocompleteObject.getVal())
    this.routeSave(this.state.from, this.state.to);
  }
  componentDidUpdate() {
    this.state.fromAutocompleteObject.setVal(this.state.from);
    this.state.toAutocompleteObject.setVal(this.state.to);
  }

  render() {
    function addRow() {
      this.state.numberOfWayPoints = this.state.numberOfWayPoints + 1;
      // set the state
      this.setState({ numberOfWayPoints: this.state.numberOfWayPoints });
    }
    const wayPointsInput = [];
    for (let i = 1; i <= this.state.numberOfWayPoints; i++) {
      wayPointsInput.push(
        <FormGroup>
          <Input placeholder={`Destination ${i}`} />
        </FormGroup>
      );
    }

    return (
      <div className="border my-2">
        <Form onSubmit={this.handleSubmit} className="selectRoute">
          <MyRoutes
            className="modal-xl "
            buttonLabel="My Routes"
            tableData={this.props.tableData}
            setRoute={this.routeSelect}
            myRoutesDelete={this.props.myRoutesDelete}
          />
          <FormGroup>
            <AlgoliaPlaces
              placesRef={autocomplete => {
                this.setState({ fromAutocompleteObject: autocomplete });
              }}
              placeholder="From"
              name="from"
              className="my-3"
              value={this.state.from}
              options={{
                appId: "plO134BHWPKJ",
                apiKey: "23d1c1fcb8e482a7bcf030bb6e8d2018",
                language: "en"

                // Other options from https://community.algolia.com/places/documentation.html#options
              }}
              onSuggestions={({ query }) => {
                this.handleInputChange("from", query);
                this.setState({ doesSuggestionWasPickedForInputFrom: false,  doesInputSelectedFromStringFrom: true });
              }}
              onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => {
                this.handleInputChange("from", suggestion.value);
                this.setState({
                  doesSuggestionWasPickedForInputFrom: true,
                  fromLatLng: suggestion.latlng,
                  selectedFromTable: null,
                  doesInputSelectedFromStringFrom: false
                });
              }}
            />
            <AlgoliaPlaces
              placesRef={autocomplete => {
                this.setState({ toAutocompleteObject: autocomplete });
              }}
              placeholder="To"
              name="to"
              value={this.state.to}
              options={{
                appId: "plO134BHWPKJ",
                apiKey: "23d1c1fcb8e482a7bcf030bb6e8d2018",
                language: "en"
                // Other options from https://community.algolia.com/places/documentation.html#options
              }}
              onSuggestions={({ query }) => {
                this.handleInputChange("to", query);
                this.setState({ doesSuggestionWasPickedForInputTo: false, doesInputSelectedFromStringTo: true });
              
              }}
              onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => {
                console.log("on change");
                this.handleInputChange("to", suggestion.value);
                this.setState({
                  doesSuggestionWasPickedForInputTo: true,
                  toLatLng: suggestion.latlng,
                  selectedFromTable: null,
                  doesInputSelectedFromStringTo: false
                });
              }}
            />
          </FormGroup>
          {/* <Button onClick={addRow.bind(this)}>Add point!</Button> */}

          {/* {wayPointsInput} */}
          <p className="border">
            Time: {this.props.time && this.msToTime(this.props.time)}
            <br />
            Distance:{" "}
            {this.props.distance &&
              (this.props.distance / 1000).toFixed(1) + " km"}
          </p>
          <Button className="button" type="submit">
            Route me!
          </Button>
          <div class=" float-right">
            <Button className="button" onClick={this.handleSaveClick}>
              {this.state.saveRouteText}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
export default SelectRouteForm;
