import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";

function TableRows(props) {
  console.log(props.tableData)
  return (
    <>
      {props.tableData.map((row, index) => (
        <tr>
          <th scope="row">{index + 1}</th>
          <td>{row.route_places[0].name}</td>
          <td>{row.route_places[1].name}</td>
          <td>
            <Button className='button' onClick={() => {props.setRoute(row.route_places[0].name, row.route_places[1].name, {lat: row.route_places[0].lat, 
            lng:row.route_places[0].lng}, {lat: row.route_places[1].lat, 
              lng:row.route_places[1].lng});
            props.toggle()
            }
            }>Select</Button>
            
          </td>
          <td><Button className='button'  onClick={() => props.myRoutesDelete(row.route.id)}   >Delete</Button></td>
        </tr>
      ))}
    </>
  );
}

const MyRoutes = props => {
  const { buttonLabel, className, routesData } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button className='button' onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>My routes</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Start</th>
                <th colspan="3">Destination</th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                tableData={props.tableData}
                setRoute={props.setRoute}
                myRoutesDelete={props.myRoutesDelete}
                toggle={toggle}
              />
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
         
          <Button className='button' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default MyRoutes;
