import React, { useState } from "react";
import { authHeader } from '../_helpers';
import {dropLast, contains} from 'ramda';
import FadeLoader from "react-spinners/FadeLoader";
import PulseLoader from "react-spinners/PulseLoader";
import {
  FormGroup,
  Modal,
  Form,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  CardColumns
} from "reactstrap";
import {userService} from '../_services/index';
import axleLoad from '../HomePage/Axle load.JPG';
import axleSpacing from '../HomePage/Axle spacing.JPG';
import wheelDistance from '../HomePage/Wheel distance.JPG';
class AddVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calulateMLC = this.calulateMLC.bind(this);
    this.handleMlcIputs = this.handleMlcIputs.bind(this);
    
    this.myRef = React.createRef();

    this.state = {
      nuberOfAxelInputs: 1,
      modal: false,
      Ma : [{Ma: ""}, {Ma: ""}],
      Wv : [{Wv: ""}],
      La : [{La: ""}, {La: ""}],
      MLC : "",
      loading: false,
      mlcLoading:false
    };
  }
  toggle() {
    const currentState = this.state.modal;
    this.setState({ modal: !currentState ,
      nuberOfAxelInputs: 1,
      Ma : [{Ma: ""}, {Ma: ""}],
      Wv : [{Wv: ""}],
      La : [{La: ""}, {La: ""}],
      MLC : ""
    });
    
  }
  setLoading(boolean){
    this.setState(
      {
        loading: boolean
      }
    )
  }
  setMlcLoading(boolean){
    this.setState(
      {
        mlcLoading: boolean
      }
    )
  }
  addRow() {
    this.state.nuberOfAxelInputs = this.state.nuberOfAxelInputs + 1;
    // set the state
    this.setState(prevState => ({
      Ma: [...prevState.Ma, {Ma: ""}],
      Wv : [...prevState.Wv, {Wv: ""}],
      La : [...prevState.La, {La: ""}],
      nuberOfAxelInputs: this.state.nuberOfAxelInputs
    }));
  }
  removeRow() {
    this.state.nuberOfAxelInputs = this.state.nuberOfAxelInputs - 1;
    // set the state
    const newMa = dropLast(1, this.state.Ma);
    console.log(newMa)
    this.setState({
      Ma: dropLast(1, this.state.Ma),
      Wv : dropLast(1, this.state.Wv),
      La :dropLast(1, this.state.La),
      nuberOfAxelInputs: this.state.nuberOfAxelInputs
    });
    
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  
  handleMlcIputs = (e) => {
    console.log(e.target.className)
    if (["Ma"].includes(e.target.className.split(' ')[0]) ) {
      console.log(e)
      let Ma = [...this.state.Ma]
      Ma[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value
      this.setState({ Ma }, () => console.log(this.state.Ma))
    } 
    if (["Wv"].includes(e.target.className.split(' ')[0]) ) {
      let Wv = [...this.state.Wv]
      Wv[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value
      this.setState({ Wv }, () => console.log(this.state.Wv))
    } 
    if (["La"].includes(e.target.className.split(' ')[0]) ) {
      let La = [...this.state.La]
      La[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value
      this.setState({ La }, () => console.log(this.state.La))
    } 
  
    
    
  }
  calulateMLC(x){
    const containsEmptyStrings = input => (
      contains({[input]: ''}, this.state[input])
    )
    if(!containsEmptyStrings('Ma') && !containsEmptyStrings('La') &&  !containsEmptyStrings('Wv'))
      {
        this.setMlcLoading(true);
        const data = {
            ma  : [...this.state.Ma.map(line => line.Ma / 1000)],
            la  : [...this.state.La.map(line => line.La)],
            wv  : [...this.state.Wv.map(line => line.Wv)]
          
        }
        fetch('https://trucktest.stricto.ee/api/vehicles/calculateMlc', {
          method: 'POST',
          headers: {  ...Object.assign({}, authHeader()),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
          body: JSON.stringify(data),
        }).then(userService.handleResponse).then( response =>{
          this.setState({MLC: response.mlccl})
          this.setMlcLoading(false);
        });
        console.log(data)
      }
      else{
        alert('fill out vehicle parameters')
      }
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setLoading(true)
    const data = new FormData(event.target);
    const object = {};
      data.forEach(function(value, key) {
        object[key] = value;
    });
      fetch('https://trucktest.stricto.ee/api/vehicles', {
        method: 'POST',
        headers:  Object.assign({}, authHeader()),
        body: data,
      }).then(userService.handleResponse).then( response =>{
        this.setLoading(false)
        console.log(response)
        this.props.vehicleSelect(response.name, response.id, response.load_data, response.total_weight)
        this.props.updateVehicles()
        this.toggle()
      });
  }

  render() {
    const InputAxelsWeight = [
      <div className="col-md-1 p-0  ml-3 ">
        <Input
        className={"Ma"}
          name="axelsWeight[]"
          id="AxelsWeight"
          placeholder={``}
          onChange={this.handleMlcIputs}
          data-id={0}
          required
        />
      </div>
    ];
    const InputAxelsWidht = [
      <div className="col-md-1 p-0  ml-3 ">
        <Input
          className={"La"}
          name="axelsWidth[]"
          id="AxelsWidht  "
          placeholder={``}
          onChange={this.handleMlcIputs}
          data-id={0}
          required
        />
      </div>
    ];
    const InputInBetween = [];
    for (let i = 1; i <= this.state.nuberOfAxelInputs; i++) {
      InputAxelsWeight.push(
        <>
          <div className="col-md-1 p-0 ml-3">
            <Input
              name="axelsWeight[]"
              id="AxelsWeight"
              className={"Ma"}
            
              placeholder={``}
              onChange={this.handleMlcIputs}
              data-id={i}
              required
            />
          </div>
        </>
      );
      InputAxelsWidht.push(
        <>
          <div className="col-md-1 p-0 ml-3">
            <Input
              name="axelsWidth[]"
              className={"La"}
              id="AxelsWidht"
              placeholder={``}
              onChange={this.handleMlcIputs}
              data-id={i}
              required
            />
          </div>
        </>
      );
      InputInBetween.push(
        <div className="col-md-1 p-0 ml-3">
          <Input
            name="inBetween[]"
            id="InBetween"
            className={"Wv"}
            placeholder={``}
            onChange={this.handleMlcIputs}
            data-id={i - 1}
            required
          />
        </div>
      );
    }
    const { buttonLabel, className } = this.props;

    return (
      <>
        <Button className="button" color="secondary" onClick={this.toggle}>
          {buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={className}
        >
          
          <ModalHeader toggle={this.toggle}> Add vehicle </ModalHeader>
          <ModalBody>
          {this.state.loading && (
            <div class="loading-addfile">
              <FadeLoader></FadeLoader>
            </div>
          )}
            <Form  ref={this.myRef}  onSubmit={this.handleSubmit}>
              <FormGroup>
                <Input
                  name="vehicleName"
                  placeholder="Vehicle name *"
                  onChange={this.handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  name="truckRegNo"
                  placeholder="Truck Reg No"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  name="trailerRegNo"
                  placeholder="Trailer Reg No"
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup className="important-text">MLC Class: {this.state.MLC === "NaN" && this.state.mlcLoading === false ? "MLC over 150. Unable to calculate" :  this.state.mlcLoading === true ?
            <PulseLoader size={'4px'} css={'display: initial;'}
          color={'#ff9603'}  /> :  this.state.MLC 
            }</FormGroup>
              <FormGroup className="add-remove-btns">
                <Button className="button mr-3" onClick={this.addRow}>+</Button>
                {this.state.nuberOfAxelInputs > 1 && <Button className="button" onClick={this.removeRow}>-</Button>}
              </FormGroup>
              <FormGroup>
                <div className="vehicle-img-container">
                  <img src={axleLoad} alt="Axle load (kg)" className="vehicle-img" />
                </div>
                <label for="axelsWeight" className="ml-3">Axle load (kg) *</label>
                <div className="row">{InputAxelsWeight}</div>
              </FormGroup>
              <FormGroup>
                <div className="vehicle-img-container">
                  <img src={wheelDistance} alt="Wheel distance (m)" className="vehicle-img" />
                </div>
                <label for="axelsWidth" className="ml-3">Wheel distance (m) *</label>
                <div className="row">{InputAxelsWidht}</div>
              </FormGroup>
              <FormGroup>
                <div className="vehicle-img-container pt-3">
                  <img src={axleSpacing} alt="Axle spacing (m)" className="vehicle-img" />
                </div>
                <label for="inBetween" className="ml-3">Axle spacing (m) *</label>
                <div className="row input-in-between">{InputInBetween}</div>
              </FormGroup>
              <Button className="button mt-3" onClick={()=> this.calulateMLC()}> Calculate MLC </Button> <Button className="button mt-3"> Done & Save </Button>
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AddVehicle;
