import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import AddVehicle from "./addVehicle"

function TableRows(props) {
  return (
    <>
    
       {props.vehicles.map((item, index) =>
             <tr>
<th scope='row'>{index + 1}</th>
       <td>{item.vehicle_data.name}</td>
             <td>{item.vehicle_data.load_data}</td>
             <td>{item.vehicle_data.truck_reg_no}</td>
             <td>{item.vehicle_data.trailer_reg_no}</td>
             <td><Button className='button' onClick={()=>{
               props.vehicleSelect(item.vehicle_data.name, item.vehicle_data.id, item.vehicle_data.load_data, item.vehicle_data.total_weight);
               props.toggle()}  }>Select</Button></td>
             <td><Button onClick={() => {props.vehicleDelete(item.vehicle_data.id);
           
            }} className="button">Delete</Button></td>
           </tr>
        )}
    </>
  );
}

export const MyVehicles = props => {
  const { buttonLabel, className, vehicleSelect, vehicles, updateVehicles, vehicleDelete } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button className='button' onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>My vehicles</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Vehicle Name</th>
                <th>MLC class</th>
                <th>Truck Reg No</th>
                <th>Trailer Reg No</th>
                <th colspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                vehicleSelect={vehicleSelect}
                vehicles={vehicles}
                vehicleDelete={vehicleDelete}
                toggle={toggle}
              />
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
        <AddVehicle className="modal-xl"  vehicleSelect={vehicleSelect} updateVehicles={updateVehicles} buttonLabel="Add Vehicle" />
         
        </ModalFooter>
      </Modal>
    </>
  );
};
