import { authHeader } from "../_helpers";

export const userService = {
  login,
  logout,
  getAll,
  getRoutes,
  getVehicles,
  addRoutes,
  handleResponse,
  getBridges
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  return fetch(
    `https://trucktest.stricto.ee/api/auth/login`,
    requestOptions
  )
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function addVehicles(
  vehicleName,
  loadData,
  truckRegNo,
  trailerRegNo,
  axelWeights,
  axelWidth,
  axelWeight,
  inBetween
) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign({}, authHeader(), {
      "Content-Type": "application/json"
    }),
    body: JSON.stringify({
      vehicleName,
      loadData,
      truckRegNo,
      trailerRegNo,
      axelWeights,
      axelWidth,
      axelWeight,
      inBetween
    })
  };
  return fetch(
    `https://trucktest.stricto.ee/api/auth/login`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {});
}
function getVehicles() {
  const requestOptions = {
    method: "GET",
    headers: Object.assign({}, authHeader(), {
      "Content-Type": "application/json"
    }),
    headers: authHeader()
  };
  return fetch(
    `https://trucktest.stricto.ee/api/vehicles`,
    requestOptions
  ).then(handleResponse);
}
function getRoutes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `https://trucktest.stricto.ee/api/routes`,
    requestOptions
  ).then(handleResponse);
}
function getBridges() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `https://trucktest.stricto.ee/api/destination/getBridges`,
    requestOptions
  ).then(handleResponse);
}

function addRoutes(from, to, optional = null) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign({}, authHeader(), {
      "Content-Type": "application/json"
    }),
    ...(optional
      ? {
          body: JSON.stringify({
            from: from,
            to: to,
            ...optional
          })
        }
      : {
          body: JSON.stringify({
            from: from,
            to: to
          })
        })
  };
  return fetch(
    `https://trucktest.stricto.ee/api/routes`,
    requestOptions
  ).then(handleResponse);
}

function getRouting(vehicleID, from, to) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `https://trucktest.stricto.ee/users`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // eslint-disable-next-line no-restricted-globals
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
