import React, { Component, useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet";
import {prop, contains} from 'ramda';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  Circle,
  CircleMarker,
  FeatureGroup
} from "react-leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});


export const MyMap = props => {
 
  
  const mapRef = useRef();
  
   // props.mapSetRef(mapRef)
   
  useEffect(() => {
 
});


  return (
    <>
      {props.polyline ? (
        <Map
          style={{ height: "85vh" }}
          ref={mapRef, props.mapSetRef}
        >
          <MapBody
            position={[58.800552, 24.857666]}
            polyline={props.polyline}
            suitableBridges={props.suitableBridges}
            notSuitableBridges={props.notSuitableBridges}
            from={props.from}
            to={props.to}
            pathBridges={props.pathBridges}
            bridgeTypeToShow={props.bridgeTypeToShow}
            path_bridges_compared_from_db={props.path_bridges_compared_from_db}
            bridges_impassable_by_rounds={props.bridges_impassable_by_rounds}
          />
        </Map>
      ) : (
        <Map center={props.position} zoom={8} style={{ height: "85vh" }}
        ref={props.mapSetRef}
        >
          <MapBody
            position={[58.800552, 24.857666]}
            polyline={props.polyline}
            suitableBridges={props.suitableBridges}
            notSuitableBridges={props.notSuitableBridges}
            allBridges={props.allBridges}
            bridgeTypeToShow={props.bridgeTypeToShow}
            path_bridges_compared_from_db={props.path_bridges_compared_from_db}
            bridges_impassable_by_rounds={props.bridges_impassable_by_rounds}
          />
        </Map>
      )}
    </>
  );
};

const MapBody = props => {
  
  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
     <FeatureGroup  >
      {props.polyline && (
        <>
          <Marker
            position={{ lat: props.polyline[0][0], lng: props.polyline[0][1] }}
          >
            <Popup>
              {props.from}
            </Popup>
          </Marker>
          <Marker
            position={{
              lat: props.polyline[props.polyline.length - 1][0],
              lng: props.polyline[props.polyline.length - 1][1]
            }}
          
          >
            <Popup>
              {props.to}
            </Popup>
          </Marker>
        </>
      )}
      {props.allBridges && props.allBridges.filter(bridge =>contains(prop('DataType',bridge), props.bridgeTypeToShow )).map(bridge => (
         <CircleMarker
         center={{ lat: bridge.Ycoord, lng: bridge.Xcoord }}
         radius={8}
         color={prop('DataType',bridge) === 1 ? "green": prop('DataType',bridge) === 2 ? "#8c8c8c":
        "#c8c8c8"}
       >
         <Popup>
           {bridge.BridgeNumber} <br /> {bridge.BridgeName}
           <br />
           MLC Rating: {bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
           
           Date of rating: {bridge.DateAllowedTotalMass}<br />Bridge BMS: {bridge.BridgeBMS}
           <br />
           Owner: {bridge.Owner}
           <br />
           Type of structure: {bridge.TypeOfStructure}
           <br />
           Year built: {bridge.YearBuilt}
           <br />
         </Popup>
       </CircleMarker>
     ))}
      ))}
      {props.bridges_impassable_by_rounds &&
      props.bridges_impassable_by_rounds.map(bridge =>
        <CircleMarker
        center={{ lat: bridge.Ycoord, lng: bridge.Xcoord }}
        radius={7}
        color= {"red"}
       >
        <Popup>
          {bridge.BridgeNumber} <br /> {bridge.BridgeName}
          <br />
          MLC Rating: {bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
          Date of rating: {bridge.DateAllowedTotalMass}<br />Bridge BMS: {bridge.BridgeBMS}
          <br />
          Owner: {bridge.Owner}
          <br />
          Type of structure: {bridge.TypeOfStructure}
          <br />
          Year built: {bridge.YearBuilt}
          <br />
        </Popup>
      </CircleMarker>
      )
      }
      {props.polyline ? <Polyline positions={props.polyline}></Polyline> : null}
      {props.suitableBridges &&
        props.suitableBridges.filter(bridge =>contains(prop('DataType',bridge), props.bridgeTypeToShow )).map(bridge => (
          <CircleMarker
            center={{ lat: bridge.Ycoord, lng: bridge.Xcoord }}
            radius={7}
            color= {prop('DataType',bridge) === 1 ? "green": prop('DataType',bridge) === 2 ? "#8c8c8c":
            "#c8c8c8"}
           >
            <Popup>
              {bridge.BridgeNumber} <br /> {bridge.BridgeName}
              <br />
              MLC Rating: {bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
              Date of rating: {bridge.DateAllowedTotalMass}<br />Bridge BMS: {bridge.BridgeBMS}
              <br />
              Owner: {bridge.Owner}
              <br />
              Type of structure: {bridge.TypeOfStructure}
              <br />
              Year built: {bridge.YearBuilt}
              <br />
            </Popup>
          </CircleMarker>
        ))}
         {props.pathBridges &&
        props.pathBridges.map(bridge => (<Circle
        center={{lat: bridge[0], lng: bridge[1]}}
        color={"black"}
        radius={10}
        > 

        </Circle>
       ))}
      {props.path_bridges_compared_from_db && props.path_bridges_compared_from_db.map(bridge => (
          <CircleMarker
          center={{ lat: bridge.Ycoord, lng: bridge.Xcoord }}
          radius={7}
          color={prop('DataType',bridge) === 1 ? "green": prop('DataType',bridge) === 2 ? "#8c8c8c":
          "#c8c8c8"} >
             <Popup>
              {bridge.BridgeNumber} <br /> {bridge.BridgeName}
              <br />
              MLC Rating: {bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
              Date of rating: {bridge.DateAllowedTotalMass}<br />Bridge BMS: {bridge.BridgeBMS}
              <br />
              Owner: {bridge.Owner}
              <br />
              Type of structure: {bridge.TypeOfStructure}
              <br />
              Year built: {bridge.YearBuilt}
              <br />
            </Popup>
          </CircleMarker>
       ))}
          
        >
      )}
      {props.notSuitableBridges &&
        props.notSuitableBridges.filter(bridge =>contains(prop('DataType',bridge), props.bridgeTypeToShow )).map(bridge => (
          <CircleMarker
            center={{ lat: bridge.Ycoord, lng: bridge.Xcoord }}
            radius={7}
            color={prop('DataType',bridge) === 1 ? "red": prop('DataType',bridge) === 2 ? "#8c8c8c":
            "#c8c8c8"}
          >
            <Popup>
              {bridge.BridgeNumber} <br /> {bridge.BridgeName}
              <br />
              MLC Rating: {bridge.AllowedTotalMass} <br /> Level of rating: 0 <br />
              Date of rating: {bridge.DateAllowedTotalMass}<br />Bridge BMS: {bridge.BridgeBMS}
              <br />
              Owner: {bridge.Owner}
              <br />
              Type of structure: {bridge.TypeOfStructure}
              <br />
              Year built: {bridge.YearBuilt}
              <br />
            </Popup>
          </CircleMarker>
        
        ))}
       </FeatureGroup>
    </>
  );
};
