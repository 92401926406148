import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { store } from './_helpers';
import { App } from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// setup fake backend
//import { configureFakeBackend } from './_helpers';
//configureFakeBackend();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("app")
);